import React from 'react';
import { Card, FormGroup, Select, MenuItem, IconButton } from '@mui/material';
import { getTaskColor } from '../helpers/ColorHelper';
import { taskTypes } from '../constants/TaskConstants';
import { InputTextField } from '../common/InputTextField';
import TaskIconButtons from './taskComponents/TaskIconButtons';
import { handleConfigChange } from './taskCommon/ConfigCommon';
import { handleGroceryComplete } from 'helpers/DataStoreHelper';
import StreakIcon from 'dailys/StreakIcon';
import { isTaskComplete } from 'helpers/TaskHelpers';
import { ArrowDropUp } from '@mui/icons-material';
import { testIds } from 'constants/TestConstants';
import ParentTaskComponents from './taskComponents/ParentTaskComponents';
import TaskTypeInputs from './taskComponents/TaskTypeInputs';
import { timeframeToMinutes } from './taskCommon/TaskCommonFunctions';

export default class TaskComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            taskName: this.props.task.taskName,
            id: this.props.task.id,
            daily: this.props.task.daily,
            duration: this.props.task.duration,
            type: this.props.task.type,
            scheduledDate: this.props.task.scheduledDate,
            scheduledTime: this.props.task.scheduledTime,
            priority: this.props.task.priority,
            completed: this.props.task.completed,
            completedDate: this.props.task.completedDate,
            category: this.props.task.category,
            dueDate: this.props.task.dueDate,
            dueDateDate: this.props.task.dueDateDate,
            user: this.props.task.user,
            version: this.props.task.version,
            ingredients: this.props.task.ingredients,
            blockedOn: this.props.task.blockedOn,
            blockedOnTask: this.props.task.blockedOnTask,
            frequencyInDays: this.props.task.frequencyInDays
        };
    }

    componentDidUpdate(prevProps) {
        // Added to try to resolve the scenario where daily is scheduled then the user
        // goes to the task page and completes the daily, this will overwrite the scheduled
        // updates if the component does pull them in
        if (this.props.task.daily !== prevProps.task.daily) {
            this.setState({ daily: this.props.task.daily });
        }
    }

    render() {
        const { deleteTask, changeTask, addSubTask, moveSubTaskUp, preferences, fridgeResponse, today, isBetaUser, pushError, isChildTask, children, addCoins } = this.props;
        const { id, taskName, daily, duration, type, scheduledDate, blockedOn, blockedOnTask } = this.state;
        const color = getTaskColor(this.state, today);

        // TODO can prolly move all this logic into ParentTaskComponents
        /*const subTaskInput = children && isBetaUser && type !== taskTypes.GROCERY ?
            
            :<>
                <p style={{ margin: 0, fontSize: 16 }}>Subtasks</p>
                <Card style={{ paddingBottom: 5, margin: 5, background: 'rgba(0, 0, 0, 0)', textAlign: 'center' }}>
                    <CardActionArea
                        onClick={addSubTask}
                        style={{ minWidth: 250, minHeight: 30 }}
                    >
                        <AddIcon style={{ position: 'relative', top: '50%' }} fontSize="large" />
                    </CardActionArea>
                </Card>
            </>;*/

        return <Card
            style={{minWidth:350, minHeight:300, display:'inline-block', padding:5, margin:5, backgroundColor:color}}
            data-testid={testIds.taskComponent}
        >
            {type !== taskTypes.SCHEDULED && daily && daily.enabled && <>
                <StreakIcon dateTimes={daily.dateTimes} today={today} completed={isTaskComplete(this.state, today)} />
                <br/>
            </>}
            {isChildTask &&<IconButton
                style={{width: '50px', display: 'inline-block', float: 'left', textAlign:'left'}}
                onClick={moveSubTaskUp}
                size="large">
                <ArrowDropUp/>
            </IconButton>}
            <TaskIconButtons 
                completed={isTaskComplete(this.state, today)}
                blocked={blockedOn && blockedOn !== ''}
                deleteTask={deleteTask}
                deleteDisabled={Boolean(children)}
                showBlocked={Boolean(!children)}
                handleConfigChange={(variableName, value) => {
                    const newState = handleConfigChange(variableName, value, changeTask, this.state, today);
                    if (variableName === 'completed') {
                        if (type === taskTypes.GROCERY && isBetaUser) {
                            //TODO move into on save once we have a way of knowing that a task was completed on a given save
                            handleGroceryComplete(this.state, fridgeResponse, pushError);
                        } 
                    }
                    this.setState(newState);
                }}
                scheduledDate={scheduledDate}
                blockedOnName={blockedOnTask ? blockedOnTask.taskName : null}
                onComplete={() => {
                    if (duration && isBetaUser) {
                        if (preferences.coinsPerTask) {
                            const timeframeInMinutes = timeframeToMinutes(preferences.coinsPerTask);
                            addCoins((duration/timeframeInMinutes) * preferences.coinsPerTask.amount);
                        } else {
                            // Default to 1 coin per 30 minutes
                            addCoins(duration/30);
                        }
                    }
                }}
                taskId={id}
            />
            <form onSubmit={(e)=>{ e.preventDefault(); /* form required for formatting */ }}>
                <InputTextField
                    name="taskName"
                    label="Task Name"
                    value={taskName || ''}
                    onChangeEvent={(value) => {
                        this.setState({ taskName: value });
                        handleConfigChange('taskName', value, changeTask);
                    }}
                />
                <FormGroup style={{padding:5}}>
                    { !children && <Select
                        variant="standard"
                        value={type || taskTypes.ASAP}
                        onChange={(event) => {
                            this.setState({ type: event.target.value });
                            handleConfigChange('type', event.target.value, changeTask, this.state, today);
                        }}
                        data-testid={testIds.taskTypeSelector}
                    >
                        <MenuItem value={taskTypes.ASAP}>ASAP</MenuItem>
                        <MenuItem value={taskTypes.SCHEDULED}>Scheduled</MenuItem>
                        {isBetaUser && <MenuItem value={taskTypes.GROCERY}>Grocery</MenuItem>}
                    </Select>}
                    <TaskTypeInputs
                        task={this.state}
                        preferences={preferences}
                        isParentTask={Boolean(children)}
                        handleConfigChange={(variableName, value) => {
                            this.setState({ [variableName]: value });
                            handleConfigChange(variableName, value, changeTask, this.state);
                        }}
                    />
                    {!children && <InputTextField
                        name="duration"
                        label="Duration in minutes" 
                        value={duration}
                        onChangeEvent={(value) => {
                            this.setState({ duration: parseInt(value) });
                            handleConfigChange('duration', parseInt(value), changeTask);
                        }}
                        isNumber={true}
                    />}
                    <ParentTaskComponents
                        isBetaUser={isBetaUser}
                        addSubTask={addSubTask}
                        color={color}
                        type={type}
                    >
                        {children}
                    </ParentTaskComponents>
                </FormGroup>
            </form>
        </Card>;
    }
}