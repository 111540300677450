import React, { useState } from 'react';
import { Modal, Button, TextField } from '@mui/material';
import { testIds } from 'constants/TestConstants';
import { modalStyle } from 'constants/StylingConstants';

type Props = {
    close: () => void,
    submitIssue: (issueTitle: string, issueDescription: string) => void
}

export default function IssueModal({ close, submitIssue }: Props) {
    const [ issueTitle, setIssueTitle ] = useState('');
    const [ issueDescription, setIssueDescription ] = useState('');

    const body = (
        <div style={{
            ...modalStyle,
            position: 'absolute',
            width: '50%',
            backgroundColor: '#fff',
            border: '2px solid #000',
            padding: '16px 32px 24px'
        }}>
            <h2 id="simple-modal-title">Submit an Issue</h2>
            <p id="simple-modal-description">
                <TextField
                    variant="standard"
                    fullWidth
                    name={'issueTitle'}
                    label={'Issue Title'}
                    value={issueTitle}
                    onChange={(event) => setIssueTitle(event.target.value)} />
                <TextField
                    variant="standard"
                    fullWidth
                    multiline
                    rows={8}
                    name={'issueDescription'}
                    label={'Issue Description'}
                    value={issueDescription}
                    onChange={(event) => setIssueDescription(event.target.value)} />
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={close}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        submitIssue(issueTitle, issueDescription);
                        close();
                    }}
                >
                    Confirm
                </Button>
            </p>
        </div>
    );

    return (
        <Modal
            open={true}
            onClose={close}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            data-testid={testIds.modal}
            style={{maxHeight: '90%', overflow: 'auto'}} //TODO this looks odd
        >
            {body}
        </Modal>
    );
}