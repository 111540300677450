import { CALENDAR_TASK_OFFSET } from 'constants/TaskConstants';
import { testIds } from 'constants/TestConstants';
import React, { PropsWithChildren, useCallback, useState } from 'react';

type Props = {
    increment: number,
    width: number,
    height: number,
    onCreate: (event: DraggableAreaEvent) => void,
};

export type DraggableAreaEvent = {
    start: number,
    end: number,
    dynamic?: boolean
}

export default function DraggableArea({
    increment, width, height, children, onCreate = (event) => { console.log(event); }
}: PropsWithChildren<Props>) {
    const [ isDragging, setIsDragging ] = useState(false);
    const [ startingY, setStartingY ] = useState(0);
    const [ mousePos, setMousePos ] = useState({
        x: 0,
        y: 0
    });

    const [ elementsCreated, setElementsCreated ] = useState([]);

    const startDragging = useCallback((e: React.MouseEvent) => {
        if (e.currentTarget !== e.target) return;
        setStartingY(mousePos.y - (mousePos.y % increment));
        setIsDragging(true);
    }, [ isDragging, setIsDragging, mousePos, setStartingY ]);

    const stopDragging = useCallback(() => {
        if (isDragging) {
            console.log('mouse up event');
            setIsDragging(false);

            /* For debugging */
            /*const newElementsCreated = [ ...elementsCreated ];
            newElementsCreated.push(<div
                key={'element-'+newElementsCreated.length}
                style={{
                    position: 'absolute',
                    textAlign: 'center',
                    left: '50%',
                    transform: 'translate(-75%, 0)',
                    top: startingY,
                    width: width * 0.9,
                    height: Math.floor((mousePos.y - startingY) / increment) * increment,
                    backgroundColor: `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255})`
                }}
            />);
            setElementsCreated(newElementsCreated);*/
            ///////////////////////////////////////////////
            
            const startValue = mousePos.y < startingY ? mousePos.y : startingY;
            const endValue = mousePos.y > startingY ? mousePos.y : startingY;
            onCreate({
                start: Math.floor(startValue / increment),
                end: Math.floor(endValue / increment),
                dynamic: true
            });
        }
    }, [ isDragging, setIsDragging, elementsCreated, setElementsCreated, startingY, mousePos ]);

    const moveMousePosition = useCallback((event: React.MouseEvent) => {
        const bounds = event.currentTarget.getBoundingClientRect();
        setMousePos({
            x: event.clientX - bounds.left,
            y: event.clientY - bounds.top
        });
    }, [ isDragging, setIsDragging ]);

    return (
        <div style={{ width: width, height: height, position: 'absolute', top: CALENDAR_TASK_OFFSET, left: 0 }}
            onMouseDown={(e) => startDragging(e)}
            onMouseMove={(e) => moveMousePosition(e)}
            onMouseUp={stopDragging}
            data-testid={testIds.draggableArea}
        >
            <>{/*elementsCreated*/}</>
            {children}
            {isDragging &&
                <div
                    style={{
                        position: 'absolute',
                        textAlign: 'center',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        top: mousePos.y < startingY ? mousePos.y : startingY,
                        width: width * 0.9,
                        height: Math.floor(Math.abs(mousePos.y - startingY) / increment) * increment,
                        borderStyle: 'solid',
                        borderRadius: '5px',
                        borderWidth: '1px'
                    }}
                />
            }
        </div>
    );
}
