import { IngredientUnit } from 'constants/MealConstants';

type Conversion = {
    dimension: string,
    conversion: number
}

// I yoinked this from here :P
// https://stackoverflow.com/questions/64241789/cooking-unit-measure-conversion-library
export class ValueUnits {
    value: number;
    units: string;
    unitsMap: {[unit: string]: Conversion} = {};

    constructor(value: string|number, units: string) {
        let fValue: number, fUnits;

        if (units && typeof value === 'number') {
            fValue = value;
            fUnits = units;
        } else if (typeof value === 'string') { // if no unit provided assume unit is in value
            const [ tValue, tUnits ] = value.replace(/\s+/g, ' ').trim().split( ' ' );
            fValue = parseFloat(tValue);
            fUnits = tUnits;
        } else {
            fValue = 0;
        }

        fUnits = fUnits || '';

        this.unitsMap = {
            [IngredientUnit.GRAM]: { dimension: 'weight', conversion: 1 },
            [IngredientUnit.POUND]: { dimension: 'weight', conversion: 1 / 0.00220462 },
            [IngredientUnit.MILLIGRAM]: { dimension: 'weight', conversion: 1 / 1000 },
          
            [IngredientUnit.LITER]:   { dimension: 'volume', conversion: 1 },      // liters is base     
            [IngredientUnit.MILLILITER]:  { dimension: 'volume', conversion: 1 / 1000 },
            [IngredientUnit.OUNCE]:  { dimension: 'volume', conversion: 1 / 33.814022702 },  // us fluid ounce
            [IngredientUnit.QUART]:  { dimension: 'volume', conversion: 1 / 1.0566882094 },  // us quart
            [IngredientUnit.PINT]:  { dimension: 'volume', conversion: 1 / 2.1133764189 },   // us pint
            [IngredientUnit.TEASPOON]: { dimension: 'volume', conversion: 1 / 202.884 }, // us teaspoon
            [IngredientUnit.TABLESPOON]: { dimension: 'volume', conversion: 1 / 67.628 }, // us tablespoon
            [IngredientUnit.CUP]: { dimension: 'volume', conversion: 1 / 4.22675 }, // us cup
        };

        if (this.unitsMap[ fUnits ]) {
            this.value = fValue;
            this.units = fUnits;
        } else {
            this.value = fValue;
            this.units = '';
        }

        
    }
    
    toUnits(units: string) {
        // If units are not correct somewhere, dont convert
        if (this.unitsMap[ units ] && this.unitsMap[ this.units ]) {
            if (this.unitsMap[ this.units ].dimension !== this.unitsMap[ units ].dimension ) {
                console.error(`Cant get correct amount due to dimensions not matching ${units}:${this.unitsMap[ units ].dimension}, ${this.units}:${this.unitsMap[ this.units ].dimension}`);
            } else {
                this.value = parseFloat( ( this.value * this.unitsMap[ this.units ].conversion / this.unitsMap[ units ].conversion ).toPrecision( 10 ) );
                this.units = units;
            }
        } else {
            console.error(`Cant get correct amount due to incorrect unit ${units}, ${this.units}`);
        }
        return this;
    }
      
    unitsDimension() {
        return this.unitsMap[ this.units ].dimension;
    }
}