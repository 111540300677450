import { DataStore } from 'aws-amplify/datastore';
import { Fridge, Goal, Issue, Note, ReleaseNotes, ScheduledInformation, ShopItem } from 'models';
import { User } from 'models';
import { MealDiaryEntry } from 'models';
import { FoodInformation } from 'models';
import { Recipe } from 'models';
import { Preference } from 'models';
import { Task } from 'models';
import { notEmpty } from './TypesHelper';
import { FridgeUpdates, GoalUpdates, IssueUpdate, NoteUpdates, PreferenceUpdates, RecipeUpdates, ShopItemUpdates, TaskUpdates, UserUpdates } from 'models/DataStoreUpdateTypes';
import { TaskUpdateMap } from 'models/CommonTypes';

export function handleGroceryComplete(original: Task, fridgeResponse: Fridge, pushError: (error: string) => void) {
    if (fridgeResponse.id && original && original.ingredients) {
        const newIngredients = fridgeResponse.ingredients ? [ ...fridgeResponse.ingredients ] : [];
        original.ingredients.forEach((ingredient) => {
            let found = false;
            newIngredients.forEach((newIngredient, index) => {
                const newIngName = newIngredient && newIngredient.name ? newIngredient.name.toLowerCase() : 'unknown';
                const fridgeIngName = ingredient && ingredient.name ? ingredient.name.toLowerCase() : 'unknown';
                if (newIngName === fridgeIngName) {
                    const newAmount = newIngredient && newIngredient.amount ? newIngredient.amount : 0;
                    const currentAmount = ingredient && ingredient.amount ? ingredient.amount : 0;
                    newIngredients[index] = {
                        name: newIngName,
                        amount: newAmount + currentAmount
                    };
                    found = true;
                }
            });

            if (!found) {
                newIngredients.push(ingredient);
            }
        });

        console.log(newIngredients);
        updateFridge(fridgeResponse.id, { ingredients: newIngredients }, pushError);
    } else {
        console.log('not able to update fridge on grocery complete');
    }
}

// TODO is forceReload required anymore?
const isForceReload = 'forceReload';
export async function saveTaskUpdates(taskUpdates: TaskUpdateMap, pushError: (error: string) => void, overwriteScheduling: boolean = false) {
    console.log(taskUpdates);
    // Needs to be for loop to enable await on updateTask
    Object.keys(taskUpdates).forEach(async (taskId) => {
        if (taskId !== isForceReload
                // There are some cases where we call save all tasks with all tasks
                //  even if there are no updates, this optimizes that case
                && Object.keys(taskUpdates[taskId]).length > 0) {

            await updateTask(taskId, taskUpdates[taskId], pushError, overwriteScheduling)
                .catch((error) => {
                    pushError(error);
                });
        }
    });
}

export async function updateTask(id: string, newTask: TaskUpdates, pushError: (error: string) => void, overwriteScheduling: boolean = false) {
    const original = await DataStore.query(Task, id)
        .catch((error) => {
            pushError(error);
            throw error;
        });

    if (original) {
        await DataStore.save(
            Task.copyOf(original, updated => {
                // TODO this is so bad, need a generic way of setting props
                updated.archived = notEmpty(newTask.archived) ? newTask.archived : updated.archived;
                updated.blockedOn = newTask.blockedOn || updated.blockedOn;
                updated.category = newTask.category || updated.category;
                updated.completed = newTask.completed !== undefined ? newTask.completed : updated.completed;
                updated.completedDate = newTask.completedDate !== undefined ? newTask.completedDate : updated.completedDate;
                updated.datesAssigned = newTask.datesAssigned || updated.datesAssigned;
                updated.dueDate = notEmpty(newTask.dueDate) ? newTask.dueDate : updated.dueDate;
                updated.dueDateDate = newTask.dueDateDate || updated.dueDateDate;
                updated.duration = notEmpty(newTask.duration) ? newTask.duration : updated.duration;
                updated.frequencyInDays = notEmpty(newTask.frequencyInDays) ? newTask.frequencyInDays : updated.frequencyInDays;
                updated.ingredients = newTask.ingredients || updated.ingredients;
                updated.priority = newTask.priority || updated.priority;
                updated.rank = newTask.rank || updated.rank;
                // Need to be able to set scheduleddate/scheduledtime to null
                // added overwriteScheduling variable since sometimes we might not pass date/time but that
                // doesnt mean we want to set them to undefined
                updated.scheduledDate = overwriteScheduling ? (newTask.scheduledDate !== undefined ? newTask.scheduledDate : updated.scheduledDate) : updated.scheduledDate;
                updated.scheduledTime = overwriteScheduling ? (newTask.scheduledTime !== undefined ? newTask.scheduledTime : updated.scheduledTime) : updated.scheduledTime;
                updated.subTasks = newTask.subTasks || updated.subTasks;
                updated.taskName = newTask.taskName || updated.taskName;
                updated.type = newTask.type || updated.type;

                if (newTask.daily) {
                    updated.daily = {
                        ...newTask.daily,
                        dateTimes: JSON.stringify(newTask.daily.dateTimes)
                    };
                }
            })
        ).catch((error) => {
            pushError(error);
            throw error;
        });
    } else {
        console.log('Error on task update');
        pushError('Cannot update Task because it does not exist.');
    }
}

export async function updatePreference(id: string, newPreference: PreferenceUpdates, pushError: (error: string) => void) {
    const original = await DataStore.query(Preference, id).catch((error) => pushError(error));

    if (original) {
        await DataStore.save(
            Preference.copyOf(original, updated => {
                // TODO this is so bad, need a generic way of setting props
                updated.archiveTime = notEmpty(newPreference.archiveTime) ? newPreference.archiveTime : updated.archiveTime;
                updated.breakFrequency = notEmpty(newPreference.breakFrequency) ? newPreference.breakFrequency : updated.breakFrequency;
                updated.breakTime = notEmpty(newPreference.breakTime) ? newPreference.breakTime : updated.breakTime;
                updated.breakfastTimeWindow = newPreference.breakfastTimeWindow || updated.breakfastTimeWindow;
                updated.coinsPerTask = newPreference.coinsPerTask || updated.coinsPerTask;
                updated.completionEffect = notEmpty(newPreference.completionEffect) ? newPreference.completionEffect : updated.completionEffect;
                updated.dinnerTimeWindow = newPreference.dinnerTimeWindow || updated.dinnerTimeWindow;
                updated.endTime = newPreference.endTime || updated.endTime;
                // TODO should this allow 0?
                updated.groceryTime = notEmpty(newPreference.groceryTime) ? newPreference.groceryTime : updated.groceryTime;
                updated.lunchTimeWindow = newPreference.lunchTimeWindow || updated.lunchTimeWindow;
                updated.nutritionLimits = newPreference.nutritionLimits || updated.nutritionLimits;
                // TODO prolly remove one of these
                updated.sortEntryOrder = newPreference.sortEntryOrder || updated.sortEntryOrder;
                updated.sortOrder = newPreference.sortOrder || updated.sortOrder;
                updated.startOfWeek = newPreference.startOfWeek || updated.startOfWeek;
                updated.startTime = newPreference.startTime || updated.startTime;

                if (newPreference.categories) {
                    const newCategories = newPreference.categories.map(category => ({
                        ...category,
                        day: category && category.day ? JSON.stringify(category.day) : undefined
                    }));
                    console.log(newCategories);

                    updated.categories = newCategories;
                }
            })
        ).catch((error) => pushError(error));
    } else {
        pushError('Cannot update Preferences because it does not exist.');
    }
}

// Amplify does not have a way to just save plain json, so dailys are a string
export function convertCategories(preference: Preference) {
    return preference = {
        ...preference,
        categories: preference.categories ? preference.categories.map(category => { return {
            ...category,
            day: category && category.day ? JSON.parse(category.day) : {}
        };}) : []
    };
}

export async function updateRecipe(id: string, newRecipe: RecipeUpdates, pushError: (error: string) => void) {
    const original = await DataStore.query(Recipe, id).catch((error) => pushError(error));

    if (original) {
        await DataStore.save(
            Recipe.copyOf(original, updated => {
                updated.description = newRecipe.description || updated.description;
                // TODO do we want to allow 0?
                updated.duration = newRecipe.duration || updated.duration;
                updated.ingredients = newRecipe.ingredients || updated.ingredients;
                updated.name = newRecipe.name || updated.name;
                updated.scheduledDays = newRecipe.scheduledDays || updated.scheduledDays;
                updated.servings = newRecipe.servings || updated.servings;
                updated.types = newRecipe.types || updated.types;
            })
        ).catch((error) => pushError(error));
    } else {
        pushError('Cannot update Recipe because it does not exist.');
    }
}

export async function updateFridge(id: string, newFridge: FridgeUpdates, pushError: (error: string) => void) {
    const original = await DataStore.query(Fridge, id).catch((error) => pushError(error));

    if (original) {
        await DataStore.save(
            Fridge.copyOf(original, updated => {
                updated.ingredients = newFridge.ingredients || updated.ingredients;
            })
        ).catch((error) => pushError(error));
    } else {
        pushError('Cannot update Fridge because it does not exist.');
    }
}

export async function updateFoodInformation(id: string, newFoodInformation: FoodInformation, pushError: (error: string) => void) {
    const original = await DataStore.query(FoodInformation, id).catch((error) => pushError(error));

    if (original) {
        await DataStore.save(
            FoodInformation.copyOf(original, updated => {
                updated.calories = notEmpty(newFoodInformation.calories) ? newFoodInformation.calories : updated.calories;
                updated.cholesterol = notEmpty(newFoodInformation.cholesterol) ? newFoodInformation.cholesterol : updated.cholesterol;
                updated.dietaryFiber = notEmpty(newFoodInformation.dietaryFiber) ? newFoodInformation.dietaryFiber : updated.dietaryFiber;
                updated.protein = notEmpty(newFoodInformation.protein) ? newFoodInformation.protein : updated.protein;
                updated.saturatedFat = notEmpty(newFoodInformation.saturatedFat) ? newFoodInformation.saturatedFat : updated.saturatedFat;
                updated.servingSize = notEmpty(newFoodInformation.servingSize) ? newFoodInformation.servingSize : updated.servingSize;
                updated.sodium = notEmpty(newFoodInformation.sodium) ? newFoodInformation.sodium : updated.sodium;
                updated.sugar = notEmpty(newFoodInformation.sugar) ? newFoodInformation.sugar : updated.sugar;
                updated.totalFat = notEmpty(newFoodInformation.totalFat) ? newFoodInformation.totalFat : updated.totalFat;
                updated.totalCarbohydrate = notEmpty(newFoodInformation.totalCarbohydrate) ? newFoodInformation.totalCarbohydrate : updated.totalCarbohydrate;
                updated.servingSizeUnit = newFoodInformation.servingSizeUnit || updated.servingSizeUnit;
                updated.name = newFoodInformation.name || updated.name;
            })
        ).catch((error) => pushError(error));
    } else {
        pushError('Cannot update Food Information because it does not exist.');
    }
}

export async function updateScheduledInformation(
    day: string,
    newScheduledInformation: ScheduledInformation,
    username: string,
    today: string,
    pushError: (error: string) => void
) {
    const original = await DataStore.query(ScheduledInformation, c => c.day.eq(day))
        .catch((error) => pushError(error));

    if (original && original.length > 1) {
        pushError('More than one scheduled information for the day');
    } else if (!original || original.length < 1) {
        const createNewInfo = {
            ...newScheduledInformation,
            owner: username,
            day: today
        };
        DataStore.save(new ScheduledInformation(createNewInfo))
            .catch((error) => pushError(error));
    } else {
        await DataStore.save(
            ScheduledInformation.copyOf(original[0], updated => {
                updated.breakfastRecipe = newScheduledInformation.breakfastRecipe || updated.breakfastRecipe;
                updated.day = newScheduledInformation.day || updated.day;
                updated.dinnerRecipe = newScheduledInformation.dinnerRecipe || updated.dinnerRecipe;
                updated.lunchRecipe = newScheduledInformation.lunchRecipe || updated.lunchRecipe;
                updated.scheduledMeals = newScheduledInformation.scheduledMeals || updated.scheduledMeals;
            })
        ).catch((error) => pushError(error));
    }
}

export async function updateMealDiaryEntry(id: string, newDiaryEntry: MealDiaryEntry, pushError: (error: string) => void) {
    const original = await DataStore.query(MealDiaryEntry, id).catch((error) => pushError(error));

    if (original) {
        await DataStore.save(
            MealDiaryEntry.copyOf(original, updated => {
                // TODO do we want to be able to set 0?
                updated.amount = newDiaryEntry.amount || updated.amount;
                updated.date = newDiaryEntry.date || updated.date;
                updated.name = newDiaryEntry.name || updated.name;
                updated.unit = newDiaryEntry.unit || updated.unit;
            })
        ).catch((error) => pushError(error));
    } else {
        pushError('Cannot update Meal Diary because it does not exist.');
    }
}

export async function updateGoal(id: string, newGoal: GoalUpdates, pushError: (error: string) => void) {
    const original = await DataStore.query(Goal, id).catch((error) => pushError(error));

    if (original) {
        await DataStore.save(
            Goal.copyOf(original, updated => {
                updated.associatedTasks = newGoal.associatedTasks || updated.associatedTasks;
                updated.date = newGoal.date || updated.date;
                updated.description = newGoal.description || updated.description;
                updated.type = newGoal.type || updated.type;
            })
        ).catch((error) => pushError(error));
    } else {
        pushError('Cannot update goal because goal does not exist.');
    }
}

export async function updateIssue(id: string, newIssue: IssueUpdate, pushError: (error: string) => void) {
    const original = await DataStore.query(Issue, id);

    if (original) {
        await DataStore.save(
            Issue.copyOf(original, updated => {
                updated.approved = notEmpty(newIssue.approved) ? newIssue.approved : updated.approved;
                updated.comments = newIssue.comments || updated.comments;
                updated.completed = notEmpty(newIssue.completed) ? newIssue.completed : updated.completed;
                updated.dateCompleted = newIssue.dateCompleted || updated.dateCompleted;
                updated.description = newIssue.description || updated.description;
                updated.tags = newIssue.tags || updated.tags;
                updated.title = newIssue.title || updated.title;
                updated.type = newIssue.type || updated.type;
                updated.upvotes = newIssue.upvotes || updated.upvotes;
            })
        ).catch((error) => {
            pushError('Error updating issue: ' + error);
        });
    } else {
        pushError('Cannot update issue because issue does not exist.');
    }
}

export async function updateReleaseNotes(id: string, newReleaseNotes: ReleaseNotes, pushError: (error: string) => void) {
    const original = await DataStore.query(ReleaseNotes, id);

    if (original) {
        await DataStore.save(
            ReleaseNotes.copyOf(original, updated => {
                updated.date = newReleaseNotes.date || updated.date;
                updated.description = newReleaseNotes.description || updated.description;
            })
        ).catch((error) => pushError(error));
    } else {
        pushError('Cannot update release notes because applicable release notes do not exist.');
    }
}

export async function updateCurrentUser(newUser: UserUpdates, username: string, pushError: (error: string) => void) {
    const originalResponse = await DataStore.query(User)
        .catch((error) => {
            pushError(error);
            throw error;
        });
    const original = originalResponse[0];

    if (!original) {
        console.log('No user object found, creating new one');
        const user = new User({ ...newUser, username });
        return DataStore.save(user);
    } else {
        return DataStore.save(
            User.copyOf(original, updated => {
                updated.beta = newUser.beta || updated.beta;
                updated.coins = newUser.coins || updated.coins;
                updated.daysScheduled = newUser.daysScheduled || updated.daysScheduled;
                updated.profilePicUrl = newUser.profilePicUrl || updated.profilePicUrl;
            })
        );
    }
}

export async function addCoinsToUser(newCoins: number, pushError: (error: string) => void) {
    const originalResponse = await DataStore.query(User)
        .catch((error) => {
            pushError(error);
            throw error;
        });
    const original = originalResponse[0];

    if (!original) {
        pushError('No user object found');
        throw 'No user object found';
    } else {
        return DataStore.save(
            User.copyOf(original, updated => {
                const currentCoins = original.coins ? original.coins : 0;
                updated.coins = currentCoins + newCoins;
            })
        );
    }
}

export async function updateShopItem(id: string, newShopItem: ShopItemUpdates, pushError: (error: string) => void) {
    const original = await DataStore.query(ShopItem, id);

    if (original) {
        await DataStore.save(
            ShopItem.copyOf(original, updated => {
                updated.cost = newShopItem.cost || updated.cost;
                updated.name = newShopItem.name || updated.name;
                updated.timesRedeemed = newShopItem.timesRedeemed || updated.timesRedeemed;
            })
        ).catch((error) => {
            pushError('Error updating shop item: ' + error);
        });
    } else {
        pushError('Cannot update shop item because it does not exist.');
    }
}

export async function updateNote(id: string, newNote: NoteUpdates, pushError: (error: string) => void) {
    const original = await DataStore.query(Note, id);

    if (original) {
        await DataStore.save(
            Note.copyOf(original, updated => {
                updated.title = newNote.title || updated.title;
                updated.content = newNote.content || updated.content;
            })
        ).catch((error) => {
            pushError('Error updating note: ' + error);
        });
    } else {
        pushError('Cannot update note because it does not exist.');
    }
}