import Alert from '@mui/material/Alert';
import React from 'react';
import FridgeComponent from './Fridge/FridgeComponent';
import { Fridge } from 'models';

type Props = {
    fridgeResponse: Fridge,
    pushError: (error: string) => void
}

export default function FridgePage({ fridgeResponse, pushError }: Props) {

    return <>
        <Alert severity="warning">This feature is currently being built and is not expected to work properly.</Alert>
        <h3>Fridge</h3>
        <FridgeComponent
            fridgeResponse={fridgeResponse}
            pushError={pushError}
        />
    </>;
}