import { AppBar, Badge, Box, Button, Card, List, ListItem, Pagination, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import IssueModal from './IssueModal';
import { issueTypes } from 'constants/IssueConstants';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { IssueListItem } from './common/IssueListItem';
import { AppBarProfile } from 'common/AppBarProfile';
import { deleteIssue, sortByUpvotes, upvoteIssue } from './common/IssueCommonFunctions';
import { DataStore } from 'aws-amplify/datastore';
import { Issue } from 'models';
import { verifySentence } from 'helpers/TextHelper';
import { useErrorHook } from 'common/ErrorHook';
import { ErrorPopUps } from 'common/ErrorPopUps';
import { paginate } from 'helpers/ListHelpers';
import { swearWords } from 'common/swearWords';
import { updateIssue } from 'helpers/DataStoreHelper';

type Props = {
    username: string,
    isMod: boolean,
    triggerAuth: () => void,
    isBetaUser: boolean
}

type PaginatedIssues = [Issue[]]

export default function Issues({ username, isMod, triggerAuth, isBetaUser }: Props) {
    const [ issueModalOpen, setIssueModalOpen ] = useState(false);
    const [ submitIssueType, setSubmitIssueType ] = useState<issueTypes|null>(null);

    const [ issues, setIssues ] = useState<Issue[]>([]);
    const [ paginatedIssues, setPaginatedIssues ] = useState<PaginatedIssues>([[]]);
    const [ currentBugPage, setCurrentBugPage ] = useState(0);

    const [ features, setFeatures ] = useState<Issue[]>([]);
    const [ paginatedFeatures, setPaginatedFeatures ] = useState<PaginatedIssues>([[]]);
    const [ currentFeaturePage, setCurrentFeaturePage ] = useState(0);

    const [ bannedWords ] = useState(swearWords.split('\r\n'));
    const { errors, pushError, cancelError } = useErrorHook([]);
    const history = useHistory();

    useEffect(() => {
        DataStore.query(Issue, c => c.type.eq(issueTypes.BUG))
            .then((response) => {
                const bugs = [ ...response ].sort(sortByUpvotes);
                setIssues(bugs);
            }).catch((error) => {
                pushError('Failed to fetch issues:' + error);
            });

        DataStore.query(Issue, c => c.type.eq(issueTypes.FEATURE))
            .then((response) => {
                const newFeatures = [ ...response ].sort(sortByUpvotes);
                setFeatures(newFeatures);
            }).catch((error) => {
                pushError('Failed to fetch issues:' + error);
            });
    }, [ setPaginatedIssues, setPaginatedFeatures ]);

    // Update issues in pagination if there is a change
    useEffect(() => {
        const bugsToDisplay = issues.filter((issue) => issue.approved && !issue.completed);
        const paginatedBugs = paginate(bugsToDisplay, 10) as PaginatedIssues;
        setPaginatedIssues(paginatedBugs);

        const featuresToDisplay = features.filter((issue) => issue.approved && !issue.completed);
        const paginatedFeatures = paginate(featuresToDisplay, 10) as PaginatedIssues;
        setPaginatedFeatures(paginatedFeatures);
    }, [ issues, setPaginatedIssues, features, setPaginatedFeatures ]);

    const submitNewIssue = useCallback((type: issueTypes|null, title: string, description: string) => {
        if (verifySentence(title, bannedWords) && verifySentence(description, bannedWords)) {
            const newIssue = {
                type,
                title,
                description,
                user: username,
                upvotes: [],
                comments: [],
                approved: false,
            };
            const newIssueObj = new Issue(newIssue);
            DataStore.save(newIssueObj)
                .then(() => {
                    console.log('successfully saved issues');
                    history.push(`/individualissue?type=${type}&id=${newIssueObj.id}`);
                })
                .catch((error) => {
                    pushError('Error creating issue: ' + error);
                });
            const newBugs = type === issueTypes.BUG ? [ ...issues, { ...newIssueObj } ] : issues;
            const newFeatures = type === issueTypes.FEATURE ? [ ...features, { ...newIssueObj } ] : features;
            setIssues(newBugs);
            setFeatures(newFeatures);
        } else {
            pushError('Submission canceled: Issue contains banned words.');
        }
    }, [ issues, features, bannedWords ]);

    const upvote = useCallback((id: string, type: issueTypes) => {
        // TODO probably a better way to go about this so we dont have to do two loops
        // Should be able to replace with findIndex
        const newIssues = type === issueTypes.BUG ? [ ...issues ] : [ ...features ];
        const issueToUpvote = newIssues.find((issue) => issue.id === id);
        if (issueToUpvote) {
            const issue = upvoteIssue(issueToUpvote, username);
            const issuesWithUpvoted = newIssues
                .map((tempIssue) => tempIssue.id === issue.id ? issue : tempIssue);
            if (type === issueTypes.BUG) {
                setIssues(issuesWithUpvoted);
            } else {
                setFeatures(issuesWithUpvoted);
            }
            updateIssue(issue.id, issue, pushError);
        } else {
            pushError('Unable to upvote issue.');
        }
    }, [ username, issues, features ]);

    const issuesToModerate = useMemo(() => {
        const allIssues = issues.concat(features);
        return allIssues.filter((issue) => !issue.approved).length;
    }, [issues, features]);

    return <div className="App">
        <Typography>
            <header className="App-header">
                { issueModalOpen &&
                <IssueModal
                    close={() => setIssueModalOpen(false)}
                    submitIssue={(title, description) => submitNewIssue(submitIssueType, title, description)}
                />
                }
                <ErrorPopUps errors={errors} cancelError={cancelError} />
                <AppBar position="fixed" style={{ top: 0 }}>
                    <Box style={{position:'absolute', left:10}}>
                        <Button onClick={() => history.push('/')} color="primary" variant="contained">
                        Home
                        </Button>
                    </Box>
                    <AppBarProfile username={username} isBetaUser={isBetaUser} triggerAuth={triggerAuth} />
                </AppBar>
                { isMod &&
                <div style={{ padding: '10px' }}>
                    <Badge badgeContent={issuesToModerate} color="primary">
                        <Button variant="contained" onClick={() => history.push('/issues/moderate')}>
                            Moderate
                        </Button>
                    </Badge>
                </div>}
                <Card style={{ width: '80%', marginTop: 10 }}>
                    <Button onClick={() => history.push('/issues/completed')} style={{ float: 'left', padding: '10px' }}>
                        Recently Pushed
                    </Button>
                    <h1>Bugs</h1>
                    <List>
                        {paginatedIssues[currentBugPage] && paginatedIssues[currentBugPage].map((issue) => {
                            return <IssueListItem
                                key={issue.id}
                                issueType={issueTypes.BUG}
                                issue={issue}
                                username={username}
                                upvote={() => upvote(issue.id, issueTypes.BUG)}
                                deleteIssue={() => deleteIssue(issue.id, pushError)}
                                isModerator={isMod}
                            />;
                        }
                        )}
                        <ListItem>
                            <Button
                                disabled={!username}
                                style={{width: '100%'}}
                                onClick={() => {
                                    setSubmitIssueType(issueTypes.BUG);
                                    setIssueModalOpen(true);
                                }}
                            >
                            Submit a Bug Report
                            </Button>
                        </ListItem>
                    </List>
                    <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
                        <Pagination
                            count={paginatedIssues.length}
                            onChange={(event, value)=> setCurrentBugPage((value-1))}
                            page={currentBugPage+1}
                        />
                    </div>
                    <h1>Feature Requests</h1>
                    <List>
                        {paginatedFeatures[currentFeaturePage] && paginatedFeatures[currentFeaturePage].map((issue) => {
                            return <IssueListItem
                                key={issue.id}
                                issueType={issueTypes.FEATURE}
                                issue={issue}
                                username={username}
                                upvote={() => upvote(issue.id, issueTypes.FEATURE)}
                                deleteIssue={() => deleteIssue(issue.id, pushError)}
                                isModerator={isMod}
                            />;
                        }
                        )}
                        <ListItem>
                            <Button
                                disabled={!username}
                                style={{width: '100%'}}
                                onClick={() => {
                                    setSubmitIssueType(issueTypes.FEATURE);
                                    setIssueModalOpen(true);
                                }}
                            >
                                Submit a Feature Request
                            </Button>
                        </ListItem>
                    </List>
                    <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
                        <Pagination
                            count={paginatedFeatures.length}
                            onChange={(event, value)=> setCurrentFeaturePage((value-1))}
                            page={currentFeaturePage+1}
                        />
                    </div>
                </Card>
            </header>
        </Typography>
    </div>;
}