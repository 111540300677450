import { Button } from '@mui/material';
import React from 'react';

type Props = {
    isApproved: boolean|undefined|null,
    isMod: boolean,
    approve: () => void,
    decline: () => void
}

export default function ModeratorButtons({ isApproved, isMod, approve, decline }: Props) {
    if (!isApproved && isMod) {
        return <>
            <Button
                style={{width: '100px', display: 'inline-block', float: 'right', textAlign:'right', backgroundColor: 'green'}}
                onClick={approve}
            >
                Approve
            </Button>
            <Button
                style={{width: '100px', display: 'inline-block', float: 'right', textAlign:'right', backgroundColor: 'red'}}
                onClick={decline}
            >
                Decline
            </Button>
        </>;
    } else {
        return <></>;
    }
}