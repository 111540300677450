import { Button } from '@mui/material';
import { Edit } from '@mui/icons-material';
import React from 'react';

type Props = {
    isEditing: boolean,
    canEdit: boolean|undefined|null,
    onEdit: () => void,
    onSave: () => void,
}

export default function EditButton({ isEditing, canEdit, onEdit, onSave }: Props) {
    if (canEdit) {
        return !isEditing
            ? <Button color="primary" onClick={onEdit} style={{float:'left'}}>
                <Edit/>
            </Button>
            : <Button color="primary" onClick={onSave} style={{float:'left'}}>
                Save
            </Button>;
    } else {
        return <></>;
    }
}