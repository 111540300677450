import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Card, CardActionArea, Switch, FormGroup, FormControlLabel, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CompletedTask from './CompletedTask';
import { DataStore } from 'aws-amplify/datastore';
import { Task } from '../models';
import TaskComponent from './TaskComponent';
import { UnsavedChangesPopup } from 'common/UnsavedChangesPopup';
import { createNewTask, findTaskInMapDFS, generateTaskMap, isTaskGroomed, removeTaskFromParent, validateTasks } from './taskCommon/ConfigCommon';
import CategoryWrapper from './taskComponents/CategoryWrapper';
import { taskRankComparator } from './taskCommon/TaskComparator';
import { deepEqual } from 'helpers/TaskHelpers';
import { logTimeToExecute } from 'helpers/DebuggingHelper';
import { addCoinsToUser, saveTaskUpdates } from 'helpers/DataStoreHelper';
import { testIds } from 'constants/TestConstants';
import AddSubtaskModal from './taskComponents/AddSubtaskModal';
import { addTaskToParent, moveTask } from './taskCommon/TaskCommonFunctions';

export default function Tasks({ ...props }) {
    const { tasks, today, preferences, username, fridgeResponse, isBetaUser, toggleTextView, pushError, setUpperUnsaved } = props;
    const taskUpdates = useRef([]);
    const [ localTasks, setLocalTasks ] = useState(tasks.sort(taskRankComparator));
    const [ unsavedChanges, setUnsavedChanges ] = useState(false);
    const [ groupCategories, setGroupCategories ] = useState(false);

    //V2
    const [ loadingTaskView, setLoadingTaskView ] = useState(false);
    const [ taskById, setTaskById ] = useState({});
    /**
     * These maps contain the task structure based on their subtasks
     * E.g. below is for parent task taskid1
     * {
     *      taskid1: { taskid2: {}, taskid3: {} },
     * }
     */
    const [ ungroomedTaskMap, setUngroomedTaskMap ] = useState({});
    const [ incompleteTaskMap, setIncompleteTaskMap ] = useState({});
    const [ completeTaskMap, setCompleteTaskMap ] = useState({});
    const [ taskMap, setTaskMap ] = useState({});
    const [ categoryMap, setCategoryMap ] = useState({});

    // Keep track of coins that will be added on save
    const coinsToAdd = useRef(0);

    const [ pendingParentId, setPendingParentId ] = useState(null);

    // If unsaved changes, show alert before leaving
    useEffect(() => {
        window.onbeforeunload = (event) => {
            // Show prompt based on state
            if (unsavedChanges) {
                const e = event || window.event;
                e.preventDefault();
                if (e) {
                    e.returnValue = '';
                }
                return '';
            }
        };
    }, [ unsavedChanges ]);

    // Added this 6/26/2023 since we expect after scheduling on tab change the tasks
    // should be refreshed and the task page should be updated to use those new tasks
    useEffect(() => {
        var doTasksMatch = true;

        const sortedTasks = logTimeToExecute(() => tasks.sort(taskRankComparator), 'sort tasks');

        // Trying this to see if we can make less rendering to speed up
        for (var index = 0; index < sortedTasks.length; index++) {
            if (!deepEqual(sortedTasks[index], localTasks[index])) {
                console.log('tasks do match so local tasks will be refreshed');
                console.log(sortedTasks[index]);
                console.log(localTasks[index]);
                doTasksMatch = false;
                break;
            }
        }

        if (!doTasksMatch) {
            console.log('setting local tasks');
            setLocalTasks(sortedTasks);
        }
    }, [ tasks ]);

    useEffect(() => {
        logTimeToExecute(() => {
            setLoadingTaskView(true);

            const response = generateTaskMap(localTasks, saveTaskUpdates, pushError);

            const taskMap = response.taskMap;
            const tempUngroomedTaskMap = {};
            const tempIncompleteTaskMap = {};
            const tempCompletedTaskMap = {};
            Object.keys(taskMap).map((taskId) => {
                const task = response.tasksById[taskId];
                if (task.completed) {
                    tempCompletedTaskMap[taskId] = taskMap[taskId];
                } else if (isTaskGroomed(task)) {
                    tempIncompleteTaskMap[taskId] = taskMap[taskId];
                } else {
                    tempUngroomedTaskMap[taskId] = taskMap[taskId];
                }
            });

            setTaskById(response.tasksById);
            setTaskMap(taskMap);
            setUngroomedTaskMap(tempUngroomedTaskMap);
            setIncompleteTaskMap(tempIncompleteTaskMap);
            setCompleteTaskMap(tempCompletedTaskMap);
            setCategoryMap(response.categoryMap);
            setLoadingTaskView(false);
        }, 'generate task map');
    // TODO for some reason if pushError is included in this effect it triggers on every task update
    }, [ localTasks ]);

    const changeTask = useCallback((id, newSettings) => {
        const newUpdates = { ...taskUpdates.current };
        newUpdates[id] = {
            ...newUpdates[id],
            ...newSettings
        };
        taskUpdates.current = newUpdates;
        setUnsavedChanges(true);
        setUpperUnsaved(true);
    }, [ ]);

    const deleteTask = useCallback((id) => {
        DataStore.delete(Task, post => post.id.eq(id))
            .then((response) => {
                console.log('Successfully deleted task');
                console.log(response);
                const newTasks = [...localTasks].filter((task) => task.id !== id);
                setLocalTasks(newTasks);
            })
            .catch((error) => pushError(error));
    }, [ localTasks ]);

    const addTask = useCallback(() => {
        createNewTask(username, pushError, null, localTasks[0])
            .then((newTaskJson) => {
                const newLocalTasks = [ ...localTasks ];
                newLocalTasks.unshift(newTaskJson);
                setLocalTasks(newLocalTasks);
            });
    }, [ username, pushError, localTasks ]);

    const handleAddSubtask = useCallback((subTaskId) => {
        const newLocalTasks = [ ...localTasks ];

        // Fetch task order information
        const parentTaskIndex = newLocalTasks.findIndex((task) => task.id === pendingParentId);
        const parentTask = newLocalTasks[parentTaskIndex];

        if (!subTaskId) {
            const taskBelow = newLocalTasks[parentTaskIndex + 1] ? newLocalTasks[parentTaskIndex + 1] : null;

            // If subtask id is null create new task
            createNewTask(username, pushError, parentTask, taskBelow)
                .then((newTaskJson) => {
                    newLocalTasks.splice(parentTaskIndex + 1, 0, newTaskJson);
                    addSubTask(newTaskJson, parentTask, parentTaskIndex, newLocalTasks);
                });
        } else {
            addSubTask(taskById[subTaskId], parentTask, parentTaskIndex, newLocalTasks);
        }
    }, [ localTasks, pendingParentId, username, pushError, taskById ]);

    const addSubTask = useCallback((subTask, parentTask, parentTaskIndex, newLocalTasks) => {
        //Add new task to parentTask subtasks
        const newSubtasks = parentTask.subTasks ? [ ...parentTask.subTasks, subTask.id ] : [ subTask.id ];

        // This makes it so user has to hit save for task to be subtask
        /* changeTask(parentTask.id, { subTasks: newSubtasks });
        const parentTaskUpdate = { ...parentTask, subTasks: newSubtasks };
        newLocalTasks.splice(parentTaskIndex, 1, parentTaskUpdate);

        setLocalTasks(newLocalTasks);
        setPendingParentId(null);*/

        // This makes it so subtask additions are saved automatically
        saveTaskUpdates({ [parentTask.id]: { subTasks: newSubtasks }}, pushError).then(() => {

            // In the case of a new task, it wont be in taskByID
            if (!taskById[subTask.id]) {
                taskById[subTask.id] = subTask;
            }

            addTaskToParent(parentTask, parentTaskIndex, subTask, changeTask, newLocalTasks, taskById, null, taskUpdates.current);
            setLocalTasks(newLocalTasks);
            setPendingParentId(null);
        });
    }, [ setLocalTasks, setPendingParentId, changeTask, taskById, taskUpdates ]);

    const moveSubTaskUp = useCallback((id) => {
        const newLocalTasks = [ ...localTasks ];

        // 1. Find task heirarchy using BFS
        const taskHeirarchy = findTaskInMapDFS(id, taskMap);

        if (taskHeirarchy.length <= 1) {
            console.error('Unable to find a parent for given task');
        }

        // 2. Update parent to not have as subtask
        const parentId = taskHeirarchy[1];
        const parentTaskIndex = newLocalTasks.findIndex((task) => task.id === parentId);
        removeTaskFromParent(id, parentId, parentTaskIndex, taskById, changeTask, newLocalTasks);

        // 3. If theres another layer add task as a subtask in that layer
        if (taskHeirarchy.length > 2) {
            const newParent = taskHeirarchy[2];
            const targetParentIndex = newLocalTasks.findIndex((task) => task.id === newParent);
            addTaskToParent(taskById[newParent], targetParentIndex, taskById[id], changeTask, newLocalTasks);
        } else {
            moveTask(id, parentId, newLocalTasks, taskById, changeTask);
        }

        setLocalTasks(newLocalTasks);
    }, [ username, pushError, localTasks, setLocalTasks, taskMap, taskById ]);

    const generateTaskComponent = useCallback((task, taskMap, isChildTask = false) => {
        return <TaskComponent
            key={task.id}
            task={task}
            deleteTask={() => (deleteTask(task.id))}
            changeTask={(newSettings) => changeTask(task.id, newSettings)}
            preferences={preferences}
            fridgeResponse={fridgeResponse}
            today={today}
            isBetaUser={isBetaUser}
            pushError={pushError}
            addSubTask={() => setPendingParentId(task.id)}
            moveSubTaskUp={() => moveSubTaskUp(task.id)}
            isChildTask={isChildTask}
            addCoins={(additionalCoins) => {
                coinsToAdd.current += additionalCoins;
            }}
        >
            {taskMap && Object.keys(taskMap).length > 0 && Object.keys(taskMap).map((taskId) =>
                generateTaskComponent(taskById[taskId], taskMap[taskId], true))
            }
        </TaskComponent>;
    }, [ preferences, fridgeResponse, today, isBetaUser, pushError, taskById ]);

    const taskComponents = useMemo(() => {
        if (groupCategories) {
            const categoryTaskKeys = Object.keys(categoryMap);

            // Sort by number of tasks in category
            categoryTaskKeys.sort(function(a, b) {
                return categoryMap[a].length - categoryMap[b].length;
            });

            return <>
                <div style={{ maxWidth: '2000px', display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
                    {categoryMap['None'].map((taskId) => {
                        return generateTaskComponent(taskById[taskId], incompleteTaskMap[taskId]);
                    })}
                </div>
                {categoryTaskKeys.map((categoryId) => {
                    if (categoryId !== 'None') {
                        const category = preferences.categories
                            .filter((category) => category.categoryId === categoryId);
                        const categoryTasks = categoryMap[categoryId];
                        return <CategoryWrapper
                            key={categoryId}
                            category={category.length > 0 ? category[0] : {}}>
                            {categoryTasks.map((taskId) => {
                                return generateTaskComponent(taskById[taskId], incompleteTaskMap[taskId]);
                            })}
                        </CategoryWrapper>;
                    }
                })}
            </>;
        } else {
            const taskComponents = logTimeToExecute(() => {
                const tasksToRender = Object.keys(incompleteTaskMap)
                    .map((taskId) => taskById[taskId])
                    .sort(taskRankComparator);
                return tasksToRender.map((task) => {
                    return generateTaskComponent(task, incompleteTaskMap[task.id]);
                });
            }, 'generate incomplete task components');
            return <div style={{ maxWidth: '2000px', display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
                { taskComponents }
            </div>;
        }
    }, [ today, preferences, groupCategories, categoryMap, incompleteTaskMap, taskById ]);

    const ungroomedTaskComponents = useMemo(() => {
        const taskComponents = logTimeToExecute(() => {
            const tasksToRender = Object.keys(ungroomedTaskMap)
                .map((taskId) => taskById[taskId])
                .sort(taskRankComparator);
            return tasksToRender.map((task) => {
                return generateTaskComponent(task, ungroomedTaskMap[task.id]);
            });
        }, 'generate ungroomed task components');
        return <div style={{ maxWidth: '2000px', display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
            { taskComponents }
        </div>;
    }, [ ungroomedTaskMap, taskById ]);

    const completedTaskComponents = useMemo(() => {
        return logTimeToExecute(() => {
            const tasksToRender = Object.keys(completeTaskMap)
                .map((taskId) => taskById[taskId])
                .sort(taskRankComparator);
            return tasksToRender.map((task) => {
                return <CompletedTask
                    key={task.id}
                    task={task}
                    deleteTask={() => (deleteTask(task.id))}
                    changeTask={(newSettings) => changeTask(task.id, newSettings)}
                    pushError={pushError}
                    blockedOnTask={task.blockedOnTask}
                />;
            });
        }, 'generate ungroomed task components');
    }, [ taskById, completeTaskMap ]);

    if (loadingTaskView) {
        return <CircularProgress/>;
    }

    return <>
        {pendingParentId &&
            <AddSubtaskModal
                parentId={pendingParentId}
                handleClose={() => setPendingParentId(null)}
                addSubtask={handleAddSubtask}
                pushError={pushError}
            />
        }
        <UnsavedChangesPopup unsavedChanges={unsavedChanges} onSave={() => {
            const validationErrors = validateTasks(taskUpdates.current);
            if (validationErrors && validationErrors.length > 0) {
                validationErrors.forEach((error) => pushError(error));
            } else {
                saveTaskUpdates(taskUpdates.current, pushError, true).then(() => {
                    // Add coins if there are some to add
                    if (coinsToAdd.current > 0) {
                        addCoinsToUser(coinsToAdd.current, pushError).then(() => {
                            console.log('coins updated');
                            coinsToAdd.current = 0;
                        });
                    }

                    console.log('tasks saved');
                    setUnsavedChanges(false);
                    setUpperUnsaved(false);
                    taskUpdates.current = [];
                });
            }
        }}/>
        <FormGroup>
            <FormControlLabel
                control={<Switch
                    data-testid={testIds.groupCategorySwitch}
                    onChange={() => setGroupCategories(!groupCategories)}
                />}
                label="Group categories"
            />
            {isBetaUser &&
                <FormControlLabel
                    control={<Switch onChange={toggleTextView}/>}
                    label="Text View"
                />
            }
        </FormGroup>
        <Card style={{ minWidth: 250, minHeight: 300, padding: 5, margin: 5, textAlign: 'center' }}>
            <CardActionArea
                onClick={addTask}
                style={{ minWidth: 250, minHeight: 300 }}
            >
                <AddIcon style={{ position: 'relative', top: '50%' }} fontSize="large" />
            </CardActionArea>
        </Card>
        { ungroomedTaskMap && Object.keys(ungroomedTaskMap).length > 0 &&
            <>
                <h2>Ungroomed</h2>
                <div
                    style={{display: 'table'}}
                >
                    {ungroomedTaskComponents}
                </div>
            </>
        }
        <h2>Incomplete</h2>
        <div
            style={{display: 'table'}}
        >
            {taskComponents}
        </div>
        <h2>Completed</h2>
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
        >
            {completedTaskComponents}
        </Grid>
    </>;
}