import { IngredientUnit } from 'constants/MealConstants';
import { ValueUnits } from './ValueUnits';
import { FoodInformation, Ingredient } from 'models';
import { FridgeNameToAmount } from 'models/CommonTypes';

export function calculateUnitizedAmount(ingredient: Ingredient, nutritionInformation: FoodInformation, type: string) {
    const servingSize = nutritionInformation.servingSize ? nutritionInformation.servingSize : 1;
    // Convert to correct units
    // If we are missing units somewhere, assume that it is "x"
    let unitizedAmount = ingredient.amount;
    if (ingredient.unit && nutritionInformation.servingSizeUnit) {
        // Ingredients have the key for some reason stupid
        const ingredientUnit = IngredientUnit[ingredient.unit] ? IngredientUnit[ingredient.unit] : ingredient.unit;
        const valUnits = new ValueUnits(ingredient.amount || 0, ingredientUnit);
        unitizedAmount = valUnits.toUnits(nutritionInformation.servingSizeUnit).value;
    }
    // Get the amount of servings
    const amount = unitizedAmount ? unitizedAmount / servingSize : 0;
    // @ts-expect-error TODO eventually make an enum for types maybe
    const value = amount * nutritionInformation[type];
    return roundToTwo(value);
}

function roundToTwo(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function getFridgeNameToAmount(fridgeIngredients: (Ingredient|null)[]): FridgeNameToAmount {
    if(fridgeIngredients) {
        return fridgeIngredients.reduce((previousIng: FridgeNameToAmount, currentIng: Ingredient|null) => {
            if (currentIng && currentIng.name && currentIng.amount) {
                return { ...previousIng, [currentIng.name.toLowerCase()]: currentIng.amount};
            }

            return { ...previousIng };
        }, {} as FridgeNameToAmount);
    } else {
        return {};
    }
}