import { Box, Button, List, ListItem } from '@mui/material';
import { Fridge, Ingredient } from 'models';
import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import IngredientListItem from '../Common/IngredientListItem';
import { testIds } from 'constants/TestConstants';
import { UnsavedChangesPopup } from 'common/UnsavedChangesPopup';
import { updateFridge } from 'helpers/DataStoreHelper';

type Props = {
    fridgeResponse: Fridge,
    pushError: (error: string) => void
}

export default function FridgeComponent({ fridgeResponse, pushError }: Props) {
    const [ localFridgeResponse, setlocalFridgeResponse ] = useState(fridgeResponse || {});
    const [ ingredients, setIngredients ] = useState(
        fridgeResponse.ingredients
            ? fridgeResponse.ingredients.filter(i => i !== null).map(i => {
                return !i || !i.id ? { ...i, id: uuidv4()} : i;
            })
            : []
    );
    const [ unsaved, setUnsaved ] = useState(false);

    const updateIngredient = useCallback((index: number, key: string, value: string|number|null) => {
        const newIngredients = [ ...(localFridgeResponse.ingredients || []) ];
        newIngredients[index] = {
            ...newIngredients[index],
            [key]: value
        };
        setlocalFridgeResponse({
            ...localFridgeResponse,
            ingredients: newIngredients
        });
        setUnsaved(true);
    }, [ localFridgeResponse ]);

    const deleteIngredient = useCallback((index: number) => {
        const newIngredients = [...ingredients];
        newIngredients.splice(index, 1);
        setIngredients(newIngredients);
        setlocalFridgeResponse({
            ...localFridgeResponse,
            ingredients: newIngredients
        });
        setUnsaved(true);
    }, [ ingredients ]);

    // TODO some kind of list for ingredients
    return <Box
        sx={{ width: '100%', maxWidth: '1000px', bgcolor: 'background.paper', margin: '7px' }}
        data-testid={testIds.fridgeComponent}
    >
        <UnsavedChangesPopup unsavedChanges={unsaved}
            onSave={() => {
                updateFridge(fridgeResponse.id, localFridgeResponse, pushError);
                setUnsaved(false);
            }}
        />
        <List>
            <ListItem>
                <Button style={{width:'100%'}} onClick={() => {
                    const newIngredients = localFridgeResponse.ingredients ? [ ...localFridgeResponse.ingredients.filter(i => i != null) ] as Ingredient[] : [];
                    // @ts-expect-error says it doesnt expect id but the id doesnt get generated it seems
                    const newIngredient = new Ingredient({ id: uuidv4() });
                    console.log(newIngredient);
                    newIngredients.unshift(newIngredient);
                    setIngredients(newIngredients);
                    setlocalFridgeResponse({
                        ...localFridgeResponse,
                        ingredients: newIngredients
                    });
                    setUnsaved(true);
                }}>+</Button>
            </ListItem>
            { ingredients.map((ingredient, index) =>
                <IngredientListItem
                    key={'fridge' + ingredient.id}
                    ingredient={ingredient}
                    updateIngredient={(key, value) => updateIngredient(index, key, value)}
                    deleteFunc={() => deleteIngredient(index)}
                />)
            }
        </List>
    </Box>;
}