import { Box, Button, ListItem, ListItemText, Menu, MenuItem } from '@mui/material';
import { TaskTextInput } from './taskComponents/TaskTextInput';
import React from 'react';
import { getTaskColor } from '../helpers/ColorHelper';
import { getCurrentDate } from '../helpers/TimeHelper';
import { handleConfigChange } from './taskCommon/ConfigCommon';
import CategoryChip from 'calendar/calendarComponents/CategoryChip';
import { isTaskComplete } from 'helpers/TaskHelpers';

export default class TaskTextComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            taskName: this.props.task.taskName,
            id: this.props.task.id,
            daily: this.props.task.daily,
            duration: this.props.task.duration,
            type: this.props.task.type,
            scheduledDate: this.props.task.scheduledDate || getCurrentDate(),
            scheduledTime: this.props.task.scheduledTime,
            priority: this.props.task.priority,
            completed: this.props.task.completed,
            completedDate: this.props.task.completedDate,
            category: this.props.task.category,
            dueDate: this.props.task.dueDate,
            dueDateDate: this.props.task.dueDateDate,
            user: this.props.task.user,
            version: this.props.task.version,
            ingredients: this.props.task.ingredients,
            anchorEl: null,
        };
    }

    render() {
        const { today, handler, collapseIcon, changeTask, addTask, deleteTask, makeChild, indent, focused, categories } = this.props;
        const { taskName, anchorEl, scheduledDate, completed, category } = this.state;
        const color = getTaskColor(this.state);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
            this.setState({ anchorEl: event.currentTarget });
        };
        const handleClose = () => {
            this.setState({ anchorEl: null });
        };

        return <ListItem
            style={{float: 'left', marginLeft: indent * 50, paddingTop: '0px', paddingBottom:'0px'}}
        >
            <ListItemText style={{ flexDirection: 'column', overflowWrap: 'break-word', overflow: 'visible', display: 'flex', whiteSpace: 'nowrap' }}>
                <Box>
                    {collapseIcon}
                    <Button
                        style={{ color: 'white', float: 'top', minWidth: '5px', display: 'inline' }}
                        onClick={handleClick}>
                        ...
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        { completed 
                            ? <MenuItem onClick={() => {
                                handleConfigChange('completed', null, changeTask, this.state);
                                this.setState({ completed: null });
                                handleClose();
                            }}>Reopen</MenuItem>
                            : <MenuItem onClick={() => {
                                handleConfigChange('completed', scheduledDate, changeTask, this.state);
                                this.setState({ completed: scheduledDate });
                                handleClose();
                            }}>Complete</MenuItem>}
                        <MenuItem onClick={deleteTask}>Delete</MenuItem>
                    </Menu>
                    {category && <CategoryChip
                        categoryId={category}
                        categories={categories}
                    />}
                    <TaskTextInput
                        color={color}
                        value={taskName}
                        completed={isTaskComplete(this.state, today)}
                        onChangeEvent={(value) => {
                            this.setState({ taskName: value });
                            handleConfigChange('taskName', value, changeTask);
                        }}
                        newLine={addTask}
                        deleteLine={deleteTask}
                        makeChild={makeChild}
                        focused={focused}
                        handler={handler}
                    />
                </Box>
            </ListItemText>
        </ListItem>;
    }
}