import { AppBar, Box, Button, Card, List, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { issueTypes } from 'constants/IssueConstants';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { IssueListItem } from './common/IssueListItem';
import { AppBarProfile } from 'common/AppBarProfile';
import { deleteIssue  } from './common/IssueCommonFunctions';
import { DataStore } from 'aws-amplify/datastore';
import { Issue } from 'models';
import { useErrorHook } from 'common/ErrorHook';
import { ErrorPopUps } from 'common/ErrorPopUps';
import { testIds } from 'constants/TestConstants';
import { updateIssue } from 'helpers/DataStoreHelper';

type Props = {
    username: string,
    isMod: boolean,
    triggerAuth: () => void,
    isBetaUser: boolean
}

export default function ModerateIssues({ username, isMod, triggerAuth, isBetaUser }: Props) {
    const [ issues, setIssues ] = useState<Issue[]>([]);
    const [ features, setFeatures ] = useState<Issue[]>([]);
    const { errors, pushError, cancelError } = useErrorHook([]);
    const history = useHistory();

    useEffect(() => {
        DataStore.query(Issue, c => c.type.eq(issueTypes.BUG))
            .then((response) => setIssues(response))
            .catch((error) => pushError(error));

        DataStore.query(Issue, c => c.type.eq(issueTypes.FEATURE))
            .then((response) => setFeatures(response))
            .catch((error) => pushError(error));
    }, []);

    const approve = useCallback((index: number, type: issueTypes) => {
        const newIssues = type === issueTypes.BUG ? [ ...issues ] : [ ...features ];
        // TODO do we need to get by index?
        const newIssue = { ...newIssues[index]};
        newIssue.approved = true;
        newIssues[index] = newIssue;
        if (type === issueTypes.BUG) {
            setIssues(newIssues);
        } else {
            setFeatures(newIssues);
        }
        updateIssue(newIssue.id, newIssue, pushError);
    }, [issues, features]);

    if (!username) {
        return <div data-testid={testIds.redirect}>
            <Redirect to='/info'/>
        </div>;
    }

    if (!isMod) {
        return <div className="App">
            <header className="App-header">
                Only users with moderation permissions can access this page.
            </header>
        </div>;
    }

    return <div className="App">
        <Typography>
            <header className="App-header">
                <AppBar position="fixed" style={{ top: 0 }}>
                    <Box style={{position:'absolute', left:10}}>
                        <Button onClick={() => history.push('/')} color="primary" variant="contained">
                            Home
                        </Button>
                    </Box>
                    <AppBarProfile username={username} isBetaUser={isBetaUser} triggerAuth={triggerAuth}/>
                </AppBar>
                <ErrorPopUps errors={errors} cancelError={cancelError} />
                <Button variant="contained" onClick={() => history.push('/issues')}>
                Back To Issues
                </Button>
                <Card style={{ width: '80%' }}>
                    <h1>Bugs</h1>
                    <List>
                        {issues.map((issue, index) => {
                            if (!issue.approved) {
                                return <IssueListItem
                                    key={index}
                                    issueType={issueTypes.BUG}
                                    issue={issue}
                                    username={username}
                                    deleteIssue={() => deleteIssue(issue.id, pushError)}
                                    isModerator={isMod}
                                    approve={() => approve(index, issueTypes.BUG)}
                                />;
                            }}
                        )}
                    </List>
                    <h1>Feature Requests</h1>
                    <List>
                        {features.map((issue, index) => {
                            if (!issue.approved) {
                                return <IssueListItem
                                    key={index}
                                    issueType={issueTypes.FEATURE}
                                    issue={issue}
                                    username={username}
                                    deleteIssue={() => deleteIssue(issue.id, pushError)}
                                    isModerator={isMod}
                                    approve={() => approve(index, issueTypes.FEATURE)}
                                />;
                            }}
                        )}
                    </List>
                </Card>
            </header>
        </Typography>
    </div>;
}