import React from 'react';
import Calendar from './calendar/Calendar';
import { getUserDate } from './helpers/TimeHelper';
import { CircularProgress } from '@mui/material';
import { Fridge, Recipe, Task } from './models';
import { DataStore } from 'aws-amplify/datastore';
import { Preference } from 'models';
import MealPrep from 'mealprep/MealPrep';
import { convertCategories } from 'helpers/DataStoreHelper';
import { FoodInformation } from 'models';
import { ScheduledInformation } from 'models';
import MealDiary from 'mealprep/MealDiary';
import { MealDiaryEntry } from 'models';
import Tasks from 'tasks/Tasks';
import TasksTextView from 'tasks/TasksTextView';
import { DateCard } from 'common/DateCard';
import NutritionFactPage from 'mealprep/NutritionFactPage';
import { v4 as uuidv4 } from 'uuid';
import { ErrorPopUps } from 'common/ErrorPopUps';
import { MAX_ERRORS } from 'common/ErrorHook';
import { formatTasks } from 'tasks/taskCommon/ConfigCommon';
import GoalPage from 'home/GoalPage';
import { Goal } from 'models';
import { MainAppBar } from 'common/MainAppBar';
import FridgePage from 'mealprep/FridgePage';
// Required for react nestable component https://www.npmjs.com/package/react-nestable?activeTab=readme
import 'react-nestable/dist/styles/index.css';
import { testIds } from 'constants/TestConstants';
import Shop from 'shop/Shop';
import { ShopItem } from 'models';
import { Note } from 'models';
import Notes from 'notes/Notes';

export default class MainPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            taskResponse: null,
            preferenceResponse: null,
            recipeRespones: null,
            fridgeResponse: null,
            notesResponse: null,
            foodInfoResponse: [],
            scheduledInformation: null,
            diaryResponse: null,
            goalResponse: null,
            shopResponse: null,
            taskTextViewToggle: false,
            errors: [],
            tab: 0,
            today: getUserDate(),
            taskUpdates: {},
            drawerOpen: false,
            mealOpen: false,
            //tabNames: [ 'My Day', 'Tasks', 'Preferences', 'Dailys'],
            screenWidth: document.documentElement.clientWidth,
            unsavedChanges: false,
            refetchLoading: false
        };
    }

    async fetchTasks() {
        const { username } = this.props;

        // Find user tasks object
        if (username) {
            DataStore.query(Task, t => t.archived.ne(true))
                .then((response) => {
                    console.log(response);
                    this.setState({ taskResponse: formatTasks(response) });
                    console.log('tasks fetched');
                })
                .catch((response) => {
                    console.log(response);
                    this.setState({ taskResponse: response });
                    this.pushError(response);
                });
        }
    }

    fetchPreferences() {
        const { username } = this.props;

        // Find user preferences object
        if (username) {
            DataStore.query(Preference)
                .then((response) => {
                    if (response.length < 1) {
                        const newPreference = {
                            user: username
                        };
                        DataStore.save(new Preference(newPreference))
                            .catch((error) => this.pushError(error));
                        this.setState({ preferenceResponse: newPreference });
                    } else {
                        this.setState({ preferenceResponse: convertCategories(response[0]) });
                    }
                })
                .catch((response) => {
                    console.log(response);
                    this.setState({ preferenceResponse: response });
                    this.pushError(response);
                });
        }
    }

    fetchRecipes() {
        const { username } = this.props;

        // Find user preferences object
        if (username) {
            DataStore.query(Recipe)
                .then((response) => {
                    this.setState({ recipeResponse: response });
                })
                .catch((response) => {
                    console.log(response);
                    this.setState({ recipeResponse: response });
                    this.pushError(response);
                });
        }
    }

    fetchNotes() {
        const { username } = this.props;

        // Find user preferences object
        if (username) {
            DataStore.query(Note)
                .then((response) => {
                    this.setState({ notesResponse: response });
                })
                .catch((response) => {
                    console.log(response);
                    this.setState({ notesResponse: response });
                    this.pushError(response);
                });
        }
    }

    fetchDiary() {
        const { username } = this.props;

        // Find user preferences object
        if (username) {
            DataStore.query(MealDiaryEntry)
                .then((response) => {
                    this.setState({ diaryResponse: response });
                })
                .catch((response) => {
                    console.log(response);
                    this.setState({ diaryResponse: [] });
                    this.pushError(response);
                });
        }
    }

    fetchFridge() {
        const { username } = this.props;

        if (username) {
            DataStore.query(Fridge)
                .then((response) => {
                    if (response.length < 1) {
                        const newFridge = {
                            owner: username
                        };
                        DataStore.save(new Fridge(newFridge));
                        this.setState({ fridgeResponse: newFridge });
                    } else {
                        this.setState({ fridgeResponse: response[0] });
                    }
                })
                .catch((response) => {
                    console.log(response);
                    this.setState({ fridgeResponse: response });
                    this.pushError(response);
                });
        }
    }

    fetchFoodInformation() {
        const { username } = this.props;

        if (username) {
            DataStore.query(FoodInformation)
                .then((response) => {
                    this.setState({ foodInfoResponse: response });
                })
                .catch((response) => {
                    console.log(response);
                    this.setState({ foodInfoResponse: response });
                    this.pushError(response);
                });
        }
    }

    fetchScheduledInformation() {
        const { username } = this.props;
        const { today } = this.state;

        if (username) {
            DataStore.query(ScheduledInformation, c => c.day.eq(today))
                .then((response) => {
                    if (response.length > 1) {
                        console.error('More than one scheduled information for the day.');
                        this.pushError('More than one scheduled information for the day.');
                        this.setState({ scheduledInformation: response[0] });
                    } else if(response.length === 1) {
                        this.setState({ scheduledInformation: response[0] });
                    } else {
                        this.setState({ scheduledInformation: {} });
                    }
                })
                .catch((response) => {
                    console.log(response);
                    this.setState({ scheduledInformation: response });
                    this.pushError(response);
                });
        }
    }

    fetchGoals() {
        const { username } = this.props;

        if (username) {
            DataStore.query(Goal)
                .then((response) => {
                    this.setState({ goalResponse: response });
                })
                .catch((response) => {
                    console.log(response);
                    this.setState({ goalResponse: response });
                    this.pushError(response);
                });
        }
    }

    fetchShop() {
        const { username } = this.props;

        if (username) {
            DataStore.query(ShopItem)
                .then((response) => {
                    this.setState({ shopResponse: response });
                })
                .catch((response) => {
                    this.setState({ shopResponse: [] });
                    this.pushError(response);
                });
        }
    }

    // Idea behind this is to allow subcomponents to call up to refetch tasks
    refetchTasks(action = () => {}) {
        this.setState({ refetchLoading: true });
        DataStore.query(Task, t => t.archived.ne(true))
            .then((response) => {
                console.log('Refetched tasks');
                this.setState({
                    taskResponse: formatTasks(response),
                    refetchLoading: false
                });
                action();
            })
            .catch((response) => {
                console.log(response);
                this.setState({
                    taskResponse: response,
                    refetchLoading: false
                });
                this.pushError(response);
                action();
            });
    }

    componentDidMount() {
        this.fetchTasks();
        this.fetchPreferences();
        this.fetchFridge();
        this.fetchRecipes();
        this.fetchFoodInformation();
        this.fetchScheduledInformation();
        this.fetchDiary();
        this.fetchGoals();
        this.fetchShop();
        this.fetchNotes();

        if (this.props.isBetaUser) {
            // TODO this variable doesnt seem to be used anymore?
            //this.setState({ tabNames: [ ...this.state.tabNames, 'Meal Prep', 'Nutrition Facts', 'Meal Diary', 'Home', 'Shop']});
        }

        window.addEventListener('resize', () => this.setState({ screenWidth: document.documentElement.clientWidth }));
    }

    handleTabChange(newValue) {
        const { unsavedChanges } = this.state;

        if (unsavedChanges) {
            if (confirm('Unsaved changes, are you sure you want to leave the page?')) {
                this.fetchTasks();
                this.fetchPreferences();
                this.fetchGoals();
                this.fetchRecipes();
                this.fetchFridge();
                this.fetchFoodInformation();
                this.fetchScheduledInformation();
                this.fetchDiary();
                this.fetchShop();
                this.fetchNotes();
                this.setState({ tab: newValue, unsavedChanges: false });
                this.toggleDrawer(false);
            }
        } else {
            this.fetchTasks();
            this.fetchPreferences();
            this.fetchGoals();
            this.fetchRecipes();
            this.fetchFridge();
            this.fetchFoodInformation();
            this.fetchScheduledInformation();
            this.fetchDiary();
            this.fetchShop();
            this.fetchNotes();
            this.setState({ tab: newValue });
            this.toggleDrawer(false);
        }
    }

    renderTaskView() {
        const {
            taskTextViewToggle,
            taskResponse,
            today,
            preferenceResponse,
            fridgeResponse,
        } = this.state;
        const { username, isBetaUser } = this.props;
        const { unsavedChanges } = this.state;

        return <div data-testid={testIds.tasks} style={{padding: '15px', width: '100%'}}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <DateCard today={today} setToday={(date) => this.setState({today: date})}/>
            </div>
            {taskTextViewToggle ?
                <TasksTextView
                    tasks={taskResponse}
                    today={today}
                    preferences={preferenceResponse}
                    username={username}
                    fridgeResponse={fridgeResponse}
                    isBetaUser={isBetaUser}
                    toggleTextView={() => {
                        if (!unsavedChanges || confirm('Unsaved changes, are you sure you want to leave the page?')) {
                            this.refetchTasks(() => this.setState({ taskTextViewToggle: false }));
                        }
                    }}
                    setUpperUnsaved={(unsaved) => this.setState({ unsavedChanges: unsaved })}
                    pushError={(error) => this.pushError(error)}
                />
                : <Tasks
                    tasks={taskResponse}
                    today={today}
                    preferences={preferenceResponse}
                    username={username}
                    fridgeResponse={fridgeResponse}
                    isBetaUser={isBetaUser}
                    toggleTextView={() => {
                        if (!unsavedChanges || confirm('Unsaved changes, are you sure you want to leave the page?')) {
                            this.refetchTasks(() => this.setState({ taskTextViewToggle: true }));
                        }
                    }}
                    setUpperUnsaved={(unsaved) => this.setState({ unsavedChanges: unsaved })}
                    pushError={(error) => this.pushError(error)}
                />
            }
        </div>;
    }

    toggleDrawer(open) {
        this.setState({ drawerOpen: open });
    }

    pushError(error, isSuccess = false) {
        const { errors } = this.state;

        if (errors.length < MAX_ERRORS) {
            const errorMessage = (typeof error === 'string' || error instanceof String) ? error : error.message;
            console.error(errorMessage);
            const errorId = uuidv4();
            this.setState((state) => ({ errors: [...state.errors, { message: errorMessage, disabled: false, id: errorId, isSuccess }] }));
        }
    }

    cancelError(id) {
        const { errors } = this.state;
        const newErrors = [ ...errors ];
        const index = newErrors.findIndex((error) => error.id === id);
        newErrors[index].disabled = true;
        this.setState({ errors: newErrors });
    }

    render() {
        const {
            taskResponse,
            tab,
            errors,
            today,
            preferenceResponse,
            recipeResponse,
            fridgeResponse,
            foodInfoResponse,
            scheduledInformation,
            diaryResponse,
            goalResponse,
            screenWidth,
            refetchLoading,
            shopResponse,
            notesResponse
        } = this.state;
        const { username, triggerAuth, isBetaUser, user } = this.props;

        return <div style={{ display: 'flex', width: '100%' }}>
            <MainAppBar
                screenWidth={screenWidth}
                isBetaUser={isBetaUser}
                username={username}
                triggerAuth={triggerAuth}
                tab={tab}
                handleTabChange={(num) => this.handleTabChange(num)}
            >
                <ErrorPopUps
                    errors={errors}
                    cancelError={(id) => this.cancelError(id)}
                />
                { tab === 0 && <>
                    <h1>My Day</h1>
                    {taskResponse && preferenceResponse && recipeResponse && fridgeResponse && scheduledInformation ? <Calendar
                        tasks={taskResponse}
                        refetchTasks={() => this.refetchTasks()}
                        refetchLoading={refetchLoading}
                        preferences={preferenceResponse}
                        today={today}
                        setToday={(date) => this.setState({today: date})}
                        scheduledInformation={scheduledInformation}
                        recipesResponse={recipeResponse}
                        fridgeResponse={fridgeResponse}
                        username={username}
                        isBetaUser={isBetaUser}
                        screenWidth={screenWidth}
                        goals={goalResponse}
                    /> : <CircularProgress />}
                </> }
                { tab === 1 && <>
                    <h1>Tasks</h1>
                    {taskResponse && preferenceResponse && fridgeResponse ? this.renderTaskView()
                        : <CircularProgress />}
                </> }
                { tab === 2 && <>
                    <h1>Goals</h1>
                    {taskResponse && goalResponse && preferenceResponse ? <GoalPage
                        goals={goalResponse}
                        username={username}
                        pushError={(error) => this.pushError(error)}
                        today={today}
                        setToday={(date) => this.setState({ today: date })}
                        tasks={taskResponse}
                        preferences={preferenceResponse}
                    />
                        : <CircularProgress />}
                </> }
                { tab === 3 && <>
                    <h1>Shop</h1>
                    { shopResponse ? <Shop
                        shopItems={shopResponse}
                        username={username}
                        user={user}
                        pushError={(error) => this.pushError(error)}
                    /> : <CircularProgress />}
                </> }
                { tab === 4 && <>
                    {notesResponse ? <Notes
                        notes={notesResponse}
                        username={username}
                        pushError={(error) => this.pushError(error)}
                    /> : <CircularProgress />}
                </> }
                { tab === 5 && <>
                    <h1>Recipes</h1>
                    {recipeResponse && fridgeResponse && foodInfoResponse ? <MealPrep
                        recipeResponse={recipeResponse}
                        fridgeResponse={fridgeResponse}
                        foodInfoResponse={foodInfoResponse}
                        username={username}
                        pushError={(error) => this.pushError(error)}
                    /> : <CircularProgress />}
                </> }
                { tab === 6 && <>
                    <h1>Nutrition Facts</h1>
                    {recipeResponse && fridgeResponse && foodInfoResponse ? <NutritionFactPage
                        foodInfoResponse={foodInfoResponse}
                        recipes={recipeResponse}
                        username={username}
                        pushError={(error) => this.pushError(error)}
                    /> : <CircularProgress />}
                </> }
                { tab === 7 && <>
                    <h1>Meal Diary</h1>
                    {diaryResponse && foodInfoResponse && preferenceResponse && recipeResponse ? <MealDiary
                        diaryResponse={diaryResponse}
                        nutritionFacts={foodInfoResponse}
                        preferences={preferenceResponse}
                        recipeResponse={recipeResponse}
                        username={username}
                        today={today}
                        setToday={(date) => this.setState({ today: date })}
                        pushError={(error) => this.pushError(error)}
                    /> : <CircularProgress />}
                </> }
                { tab === 8 && <>
                    <h1>Fridge</h1>
                    { fridgeResponse ? <FridgePage
                        fridgeResponse={fridgeResponse}
                        pushError={(error) => this.pushError(error)}
                    /> : <CircularProgress />}
                </> }
            </MainAppBar>
        </div>;
    }
}